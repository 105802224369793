<template>
  <div class="share">
    <van-sticky>
      <van-nav-bar title="分享" left-arrow @click-left="goBack" />
    </van-sticky>

    <div id="capture">
      <img class="my-share-img" :src="require('../assets/share/mark-bg.jpg')" />
      <div class="title">扫码预约</div>
      <div id="qrcodebox"></div>
      <div class="bottom-box">
        <div class="box-left">
          <div class="logo">
            <van-image
              round
              width="6rem"
              height="6rem"
              :src="require('../assets/logo.jpg')"
            />
            <div>廊坊市新朝阳医院</div>
          </div>
        </div>
        <div class="box-right">
          <p>地址：廊坊市安次区永兴路136号</p>
          <p>电话：151 0060 6726</p>
          <p>注意事项：扫码预约可享受检测优惠政策</p>
        </div>
      </div>
    </div>
    <img id="img" />
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";

export default {
  name: "Share",
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      coupon: {},
      privQrcode: null,
      code: "",
      active: 1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let $this = null;
      $this = this;
      // 获取个人信息
      this.axios({
        url: this.$store.state.api + "/my/info",
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        if (res.data) {
          $this.userInfo = res.data;
          this.code = $this.userInfo.invitationCode;
          $this.privQrcode = new QRCode(document.getElementById("qrcodebox"), {
            width: 200,
            height: 200,
            text: "https://xcy.kooshui.com?code=" + this.code, // 二维码地址
            colorDark: "#000",
            colorLight: "#fff",
          });
          setTimeout(() => {
            html2canvas(document.querySelector("#capture"), {
              useCORS: true,
            }).then((canvas) => {
              // 生成图片
              // document.body.appendChild(canvas)
              document.getElementById("capture").style.display = "none";
              //document.getElementById("show").style.display = "none";
              const tempSrc = canvas.toDataURL("image/png");
              document.getElementById("img").src = tempSrc;
            });
          }, 300);
        }
      });

      // 更新分享按钮内容
      // this.axios({
      //   url: this.$store.state.api + "/wx_shear?url=http://xcy.kooshui.com/d/" + this.code,
      //   method: "get",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     Authorization: this.Authorization,
      //   },
      // })
      //   .then((response) => {
      //     let wxConfig = null;
      //     wxConfig = response.data;
      //     window.wx.config(wxConfig); // wx初始化配置
      //     window.wx.ready(function () {
      //       //需在用户可能点击分享按钮前就先调用
      //       window.wx.updateAppMessageShareData({
      //         title: $this.coupon.title, // 分享标题
      //         desc: $this.coupon.memo ? $this.coupon.memo : "廊坊时代推荐", // 分享描述
      //         link: "https://www.lfsd.vip/d/" + $this.coupon.shearurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //         imgUrl: $this.coupon.img, // 分享图标
      //         success: function () {
      //           // 设置成功
      //         },
      //       });
      //       window.wx.updateTimelineShareData({
      //         title: $this.coupon.title, // 分享标题
      //         link: "https://www.lfsd.vip/d/" + $this.coupon.shearurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //         imgUrl: $this.coupon.img, // 分享图标
      //         success: function () {
      //           // 设置成功
      //         },
      //       });
      //     });
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.my-share-img {
  width: 100%;
  display: block;
}
.van-nav-bar .van-icon-arrow-left {
  font-size: 24px;
  color: #969799;
}
#capture {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  .title {
    position: absolute;
    top: 180px;
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
  }
  .bottom-box {
    background: #95d2f5;
    overflow: hidden;
    width: 100%;
    color: #ffffff;
    .box-left {
      float: left;
      width: 100px;
      padding: 10px 10px;
    }
    .box-right {
      float: right;
      width: calc(100vw - 150px);
      padding-right: 10px;
      p {
        text-align: left;
      }
    }
  }
}

#qrcodebox {
  position: absolute;
  top: 240px;
  left: 50%;
  margin-left: -115px;
  padding: 15px;
  background: #ffffff;
  border-radius: 6px;
}
#img {
  width: 100%;
}
</style>
